<template>
  <form @input="$emit('input', letterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.word" placeholder="Correct Word" />

      <div class="ml-40">
        <div v-for="(image, i) in item.images" :key="i">
          <input type="text" v-model="image.word" placeholder="Word" />
          <input type="text" v-model="image.image" placeholder="Image link" />
          <hr v-if="letterWords.length > 1" />
        </div>
        <button
          type="button"
          @click="
            item.images.push({
              word: '',
              image: '',
            })
          "
        >
          + Add Images
        </button>
        <button
          type="button"
          class="ml-10"
          @click="
            copyItem(item)
          "
        >
          ][ Copy
        </button>
        <button
          type="button"
          class="ml-10"
          @click="
            removeLast(item)
          "
        >
          -X- Remove
        </button>
      </div>
    </div>

    <button
      type="button"
      @click="
        letterWords.push({
          word: '',
          images: [
            {
              word: '',
              image: '',
            },
          ],
        })
      "
    >
      + Add Item
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      letterWords: [
        {
          word: '',
          images: [
            {
              word: '',
              image: '',
            },
          ],
        },
      ],
    }
  },
  methods: {
    copyItem(item) {
      let lastItem = item.images[item.images.length - 1]
      let itemCopy = Object.assign({}, lastItem)
      item.images.push(itemCopy)
    },
    removeLast(item) {
      item.images.splice(-1, 1)
    }
  },
};
</script>
