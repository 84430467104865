var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"input":function($event){return _vm.$emit('input', _vm.letterWords)}}},[_vm._l((_vm.letterWords),function(item,i){return _c('div',{key:i},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.word),expression:"item.word"}],attrs:{"type":"text","placeholder":"Correct Word"},domProps:{"value":(item.word)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "word", $event.target.value)}}}),_c('div',{staticClass:"ml-40"},[_vm._l((item.images),function(image,i){return _c('div',{key:i},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(image.word),expression:"image.word"}],attrs:{"type":"text","placeholder":"Word"},domProps:{"value":(image.word)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(image, "word", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(image.image),expression:"image.image"}],attrs:{"type":"text","placeholder":"Image link"},domProps:{"value":(image.image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(image, "image", $event.target.value)}}}),(_vm.letterWords.length > 1)?_c('hr'):_vm._e()])}),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return item.images.push({
            word: '',
            image: '',
          })}}},[_vm._v(" + Add Images ")]),_c('button',{staticClass:"ml-10",attrs:{"type":"button"},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" ][ Copy ")]),_c('button',{staticClass:"ml-10",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeLast(item)}}},[_vm._v(" -X- Remove ")])],2)])}),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.letterWords.push({
        word: '',
        images: [
          {
            word: '',
            image: '',
          } ],
      })}}},[_vm._v(" + Add Item ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }